<template>
  <base-page-layout title="ERPs">
    <v-card class="mt-3" flat>
      <v-card-title class="mb-6">
        <v-text-field v-model="search" class="pt-0 mt-0" outlined width="300" hide-details single-line="" placeholder="Busca (nome)" append-icon="search" />
        <v-spacer />
        <v-btn color="primary" to="/erps/create">Criar ERP</v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table :loading="$apollo.queries.adminErps.loading" :items="erps" :headers="headers" :search="search">
          <template v-slot:[`item.name`]="{ item }">
            <v-flex>
              <v-layout>
                {{ item.name }}
              </v-layout>
            </v-flex>
          </template>
          <template v-slot:[`item.situation`]="{ item }">
            <v-flex>
              <v-layout>
                <v-icon class="mr-1" small :color="getSituation(item.situation).color"> fiber_manual_record </v-icon>
                {{ getSituation(item.situation).text }}
              </v-layout>
            </v-flex>
          </template>
          <template v-slot:[`item.cnpj`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ item.cnpj | cnpj }}
              </v-layout>
            </v-flex>
          </template>
          <template v-slot:[`item.contacts`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                <a @click="toggleContactsDialog(item)"> {{ getLabelContacts(item.contacts) }} </a>
              </v-layout>
            </v-flex>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu :close-on-content-click="false">
              <template #activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="redirect(item._id)">
                  <v-list-item-title>Ver dados do ERP</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
        <contacts-details-dialog v-model="isDialogOpen" :item-selected="itemSelected" />
      </v-card-text>
    </v-card>
  </base-page-layout>
</template>

<script>
import { QUERY_GET_ERPS } from '@/modules/erps/graphql'

export default {
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout.vue'),
    ContactsDetailsDialog: () => import('@/components/base/ContactsDetailsDialog.vue')
  },
  data: () => ({
    search: '',
    erps: [],
    isDialogOpen: false,
    itemSelected: {}
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Nome',
          value: 'name',
          width: '10%'
        },
        {
          text: 'Situação',
          value: 'situation',
          width: '10%'
        },
        {
          text: 'CNPJ',
          value: 'cnpj',
          align: 'center',
          width: '10%',
          sortable: false
        },
        {
          text: 'Contatos',
          value: 'contacts',
          align: 'center',
          sortable: false,
          width: '10%'
        },
        {
          text: '',
          value: 'actions',
          width: '10%',
          sortable: false
        }
      ]
    }
  },
  apollo: {
    adminErps: {
      query: QUERY_GET_ERPS,
      fetchPolicy: 'network-only',
      update({ adminErps }) {
        this.erps = adminErps
      }
    }
  },
  methods: {
    getLabelContacts(contacts) {
      if (!contacts) return 'Nenhum contato'
      return contacts.length ? `${contacts.length} contato${contacts.length === 1 ? '' : 's'}` : 'Nenhum contato'
    },
    redirect(id) {
      this.$router.push(`/erps/${id}`)
    },
    getSituation(value) {
      if (!value) {
        return {
          text: 'Não informado',
          color: '#898989'
        }
      }
      const result = [
        {
          text: 'Integrado',
          value: 'integrated',
          color: '#2c67e6'
        },
        {
          text: 'Integrando',
          value: 'integrating',
          color: '#07DD18'
        },
        {
          text: 'Pausado ou não integrado',
          value: 'not_integrated',
          color: '#FACA00'
        }
      ].find(situation => situation.value === value)
      return result
    },
    toggleContactsDialog(itemSelected) {
      this.itemSelected = itemSelected
      this.isDialogOpen = true
    }
  }
}
</script>
