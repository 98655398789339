var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-page-layout',{attrs:{"title":"ERPs"}},[_c('v-card',{staticClass:"mt-3",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"mb-6"},[_c('v-text-field',{staticClass:"pt-0 mt-0",attrs:{"outlined":"","width":"300","hide-details":"","single-line":"","placeholder":"Busca (nome)","append-icon":"search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","to":"/erps/create"}},[_vm._v("Criar ERP")])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"loading":_vm.$apollo.queries.adminErps.loading,"items":_vm.erps,"headers":_vm.headers,"search":_vm.search},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}},{key:"item.situation",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":_vm.getSituation(item.situation).color}},[_vm._v(" fiber_manual_record ")]),_vm._v(" "+_vm._s(_vm.getSituation(item.situation).text)+" ")],1)],1)]}},{key:"item.cnpj",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(_vm._f("cnpj")(item.cnpj))+" ")])],1)]}},{key:"item.contacts",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('a',{on:{"click":function($event){return _vm.toggleContactsDialog(item)}}},[_vm._v(" "+_vm._s(_vm.getLabelContacts(item.contacts))+" ")])])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.redirect(item._id)}}},[_c('v-list-item-title',[_vm._v("Ver dados do ERP")])],1)],1)],1)]}}],null,true)}),_c('contacts-details-dialog',{attrs:{"item-selected":_vm.itemSelected},model:{value:(_vm.isDialogOpen),callback:function ($$v) {_vm.isDialogOpen=$$v},expression:"isDialogOpen"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }